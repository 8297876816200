import { defineStore } from 'pinia'
import Qs from 'qs'

export const useUpsellsSiteEventsStore = defineStore({
    id: 'upsells_site_events',
    state(){
        return {
          upsellsSiteEvents: [],
          loading: false,
          reachedBottom: false,
        }
    },
    actions: {
      async loadUpsellsSiteEventsHistory(page = 1, perPage = 10) {
        try {
          const params = { page: page, per_page: perPage };
          this.loading = true;
          const res = await this.$nuxt.$axios.$get(`${process.env.API_V1_URL}/upsells/site_events`, {
            params,
            paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'brackets', encode: false }),
          });

          this.upsellsSiteEvents.push(...res.data);

          if(res.meta.pagination.current_page === res.meta.pagination.total_pages) {
            this.reachedBottom = true;
          }
        } catch (err) {
          this.$nuxt.$toasts.add({
              type: 'error',
              title: 'Failed to load upsells events history!',
              subtitle: error.response.data.errors.join(".\n"),
              error
          })
        } finally {
          this.loading = false;
        }
      },
    }
})
